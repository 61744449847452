.content-pane{
    display: flex;
    flex-direction: row;
}

.filter-pane {
    display: flex;
    height: fit-content;
    width: 35%;
    padding: 2rem;
    border-radius: 3%;
    border: 1px solid #ddd;
}

.main-content {
    display: flex;
    width: 65%;
}

@media (max-width: 767px) {
   .filter-pane {
       display: none;
   }

   .main-content {
       width: 100%;
   }
}