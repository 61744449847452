@import "./CustomBootstrap.css";

html,
main,
body,
#root {
    height: 100%;
    margin: 0s;
    display: flex;
    flex-direction: column;
}

.App {
    flex-direction: column;
    flex-grow: 0.9;
}

.square-dropdown::after {
    content: none;
}

.page-item.square-pagination-item .page-link {
    border-radius: 0px !important;
    border-right: none !important;
    border-left: none;
}


.container-relative {
    position: relative;
}

.page-container {
    display: flex;
    flex-direction: column;
}