.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.login-in-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}