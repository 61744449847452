@import "../../../Styles/variables.scss";

.card-style {
    position: relative;
    width: 100%;
    padding-top: 50%;
    background-color: $secondary;
    transition: transform 0.2s;
    will-change: transform; /* Inform the browser about the upcoming transform changes */
    backface-visibility: hidden; /* Prevent the front of the card from being visible during the backface */
}

.card-style:hover {
    transform: scale(1.01);
    cursor: pointer;
}

