.offer-card {
    width: 100% !important;
    height: fit-content !important;
    margin: 0;

    .offer-card-img-col {
        position: relative;
        overflow: hidden;
        // height: 12rem;
        width: 12rem;
        aspect-ratio: 1/1;
    }

    .offer-card-img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    .offer-card-title {
        font-size: 1rem;
    }

    .offer-card-text {
        font-size: 0.8rem;
    }

    @media (max-width: 767px) {
        .offer-card-img-col {
            justify-content: center;
            width: 6rem;
            aspect-ratio: 1/1;
        }

        .offer-card-img {
            object-fit: cover;
        }

        .offer-card-title {
            font-size: 0.9rem; // Smaller text for mobile
        }

        .offer-card-text {
            font-size: 0.7rem; // Smaller text for mobile
        }

        .offer-card-body {
            padding: 1rem;
        }
    }
    transition: transform 0.2s;
    will-change: transform; /* Inform the browser about the upcoming transform changes */
    backface-visibility: hidden; /* Prevent the front of the card from being visible during the backface */
}

.offer-card:hover {
    transform: scale(1.01);
    cursor: pointer;
}

.white-icon {
    color: #E8F9FD;
}