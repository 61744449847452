.login-box {
    color: #FFFFFF;
    height: fit-content;
    padding-top: 2rem;
    padding-bottom: 2rem;
    border: 1px solid var(--bs-border-color);
    border-radius: 3%;
}

.register {
    height: fit-content;
    margin-top: 1rem;
    color: #FFFFFF;
}

.form-group {
    margin-bottom: 1rem;
}

.button {
    width: 100%;
    background-color: var(--bs-primary);
    color: #FFFFFF;
    border: none;
    border-radius: 3%;
    font-size: 1rem;
    margin-bottom: 1rem;
}

.logo{
    margin: 3vh auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 10vh;
}

.login-background {
    position: relative;
    margin-top: 15vh;
    background-color: var(--bs-dark);
    height: fit-content;
    padding-top: 2rem;
    padding-bottom: 2rem;
    width: 40vw !important;
    border: 1px solid var(--bs-border-color);
    border-radius: 3%;

    @media (max-width: 990px) {
        width: 60vw !important;
    }

    @media (max-width: 768px) {
        width: 90vw !important;
    }
}